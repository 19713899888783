@import '~antd/dist/antd.css';

.anticon {
  transition: all 200ms ease;
}
.anticon.anticon-delete:hover,
.anticon.anticon-edit:hover,
.anticon.anticon-eye:hover {
  font-size: 18px !important;
}
.ant-tabs-tab-btn {
  color: #808080;
}
.ant-tabs-tab:hover {
  color: #365680 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #365680 !important;
}
.ant-tabs-ink-bar {
  background-color: #365680 !important;
}

.tab-conatiner {
  border-radius: 10px;
  min-height: 450px;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}
.table-cotainer .ant-table-container {
  max-height: 67vh;
  overflow: scroll;
}

/* First Form */

.first-form-container {
  display: flex;
  justify-content: center;
}

.first-form-inner-container {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}

.calendar-container {
  margin-top: 8px;
}

.calendar-container > span strong {
  font-weight: 400;
  font-size: 13px;
  margin-top: 20px;
}

.first-form-button-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.upload-file-container {
  border-radius: 5px;
  width: 100%;
  margin-top: 8px;
}

.upload-file-container div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* second Form */

.second-form-container {
  width: 80%;
  padding: 20px 50px;
}
.second-form-inner-container {
  width: 70%;
  margin: 30px 50px;
}

.second-form-button-container {
  margin-top: 150px;
  display: flex;
  justify-content: center;
}

/* admin tab */

.admin-tab-container {
  padding: 20px 50px;
}
.admin-tab-inner-container {
}

.admin-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 0px 0px 20px 0px;
}

/* Third Form */
.third-form-container {
  width: 100%;
  max-height: 53vh;
  overflow: scroll;
}
.gutter-row {
  align-items: flex-end;
}

/* Fourth Form */
.file-uploader {
  margin-top: 50px;
}
.file-uploader span {
  display: flex;
  justify-content: center;
}
.fourth-form-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

/* ribbon wrapper */
.ant-ribbon-wrapper {
  height: 100%
}
