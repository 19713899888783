.input-container > input {
  border-radius: 5px;
}
.input-container > span {
  line-height: 2.5;
}
.input-container > span strong {
  font-weight: 400;
  font-size: 13px;
}

/* Student Form */

.form-container {
  width: 80%;
  padding: 20px 50px;
}
.form-inner-container {
  width: 70%;
  margin: 30px 50px;
}
.form-input-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.form-logo-container {
  width: 100px;
}

.form-input-container span {
  width: 85%;
}
.form-input-container .input-container {
  width: 85%;
}
.form-button-container {
  margin-top: 100px;
  display: flex;
  justify-content: flex-end;
}
.ant-space-item .ant-card-bordered {
  width: 100% !important;
  border-radius: 5px;
}
.add-button-container {
  margin-top: 60px;
  width: 80%;
  display: flex;
  justify-content: center;
}
.add-button-container > div {
  display: grid;
}
.add-field:hover {
  color: rgb(112, 182, 70) !important;
}

/* Recorder Container */

.recorder-container {
  margin-top: 15px;
}
.note {
  color: #979797 !important;
  font-size: 11px;
}
.heading > strong {
  font-weight: 400 !important;
  font-size: 13px;
}

.ant-upload-list-item-name,
.anticon-paper-clip,
.anticon-delete {
  color: green !important;
}
