.student-form-container {
  display: flex;
  justify-content: center;
}
.header-container-student {
  background-color: #1d3557;
  text-align: center;
  width: 100%;
  padding: 10px 0px;
}
.header-container-student img {
  height: 35px;
}
.student-heading-container {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  /* box-shadow: 0 3px 2px -2px gray; */
  border-bottom: 0.5px solid rgb(165, 165, 165);
}
.student-heading-container h3 {
  font-weight: 300;
  margin-top: 3px;
}
.student-icon-container {
  background-color: #dcdcdc;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 5px;
}
.student-icon {
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 22px;
}
.success-message-container {
  display: grid;
  margin-top: 100;
}
.success-message-container span {
  text-align: center;
}
