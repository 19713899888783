.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  height: 70px;
}
.header-container {
  height: 11vh;
  background-color: #f9f9f9 !important;
}
.header-container div {
  display: flex;
}
.heading-container {
  height: 11vh;
}
.heading-container div {
  display: flex;
  width: 100%;
}
.user-container {
  justify-content: flex-end;
  width: 100%;
}
.user-container div {
  justify-content: flex-end;
  padding-left: 5px;
}
.ant-layout-sider-dark {
  background-color: #365680 !important;
  flex: 0 0 230px !important;
  max-width: 230px !important;
  min-width: 230px !important;
  width: 230px !important;
}
.drawerItem-image {
  width: 18px;
  height: 16px;
}
.ant-layout-header {
  max-height: 0px;
}

.drawerItem:hover {
  color: white;
  -webkit-filter: invert(0) sepia(1) saturate(1) hue-rotate(175deg);
  filter: invert(0) sepia(1) saturate(1) hue-rotate(175deg);
  opacity: 1;
}
.drawerItem.active {
  background-color: #0f0f12;
  color: white;
  -webkit-filter: invert(0) sepia(1) saturate(1) hue-rotate(175deg);
  filter: invert(0) sepia(1) saturate(1) hue-rotate(175deg);
  opacity: 1;
}

.sideDrawer {
  min-width: 220px !important;
  position: fixed;
  left: 0;
  top: 64px;
  height: 100%;
  z-index: 10;
  background-color: #1f1f25;
}
.sideDrawer ul {
  background-color: #1f1f25;
  border-right: 1px solid #1f1f25;
}
.sideDrawer ul .ant-menu-sub {
  background-color: #1f1f25;
  color: white;
}
.sideDrawer ul .ant-menu-sub li a {
  color: #bec4c6;
}
.sideDrawer > ul {
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.ant-menu-sub:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white;
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.sideCollapsed {
  min-width: 50px !important;
}

.ant-layout-sider-trigger svg {
  fill: white;
}

.header-Nav {
  background-color: #0f0f12;
  height: auto !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.layout-logo {
  min-width: 200px;
}

.layout-logo img {
  max-width: 109px;
}

.layout-logo span {
  padding-left: 15px;
}

.layout-logo h3 {
  font-size: 22px;
  margin: 0px;
  color: white;
}
.drawerItem {
  display: flex;
  align-items: center;
  opacity: 0.5;
}
.drawerItem span {
  font-size: 17px;
  padding-left: 8px;
  color: #bec4c6;
}
.drawerItem span a {
  font-size: 17px;
  color: #bec4c6;
}
.drawerItem span a:hover {
  color: white;
}
.drawerItem span:hover {
  color: white;
}
.drawerItem .ant-menu-submenu-arrow:hover {
  color: white;
}
.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background-color: white !important;
}
.collapseditem span {
  opacity: 0;
  max-width: 0;
}
.nav-user {
  padding: 0 10px;
}

.nav-user span {
  font-size: 17px;
  color: white;
}

.nav-dropDown {
  padding: 0 0 0 30px;
}

.drop-icon svg {
  fill: black;
}

.bell-icon {
  font-size: 18px;
}

.bell-icon svg {
  fill: white;
}

.content-div {
  overflow-y: auto;
}

.nav-user span {
  font-size: 14px;
  color: white;
}
.custom-navbar {
  padding: 0 24px;
}

.sidebar-right-content {
  padding: 0 20px 0 240px;
  margin-top: 84px;
  background-color: #f2f3f8;
}
.sidebar-right-content-collapsed {
  padding: 0 20px 0 100px;
  margin-top: 84px;
  background-color: #f2f3f8;
}
.header-top-div {
  position: relative;
  height: 100%;
}
.main-content {
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
}
.main-content::-webkit-scrollbar {
  display: none;
}
.ant-menu-dark {
  background-color: #365680 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #49668d !important;
}
.ant-menu-item.ant-menu-item-selected a {
  color: white !important;
}
.ant-menu-item svg {
  margin-bottom: 5px !important;
}
.ant-menu-submenu svg {
  margin-bottom: 5px !important;
}
.ant-menu-item.ant-menu-item-selected svg {
  fill: white !important;
}
.ant-menu-item:hover svg {
  fill: white !important;
}
.ant-menu-item:active {
  background: transparent;
}
.ant-menu-submenu:active {
  background: transparent;
}
.ant-menu-submenu:hover svg {
  fill: white !important;
}
.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  background: white !important;
}
.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  background: white !important;
}
.heading-color .anticon-down svg {
  height: 12px;
}
