.auth-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.auth-img{
    width: 500px;
    object-fit:contain;
}
.logo-img{
    width: 200px;
    object-fit:contain;
}